/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\nmutation GenerateStreamPublisherApiKey($streamId: ID!) {\n  generateStreamPublisherApiKey(streamId: $streamId)\n}\n": types.GenerateStreamPublisherApiKeyDocument,
    "\nmutation GenerateStreamReaderApiKey {\n  generateStreamReaderApiKey\n}\n": types.GenerateStreamReaderApiKeyDocument,
    "\n  fragment AwardFragment on Medal {\n    id\n    name\n    image {\n      key\n    }\n  }\n  ": types.AwardFragmentFragmentDoc,
    "\nfragment DiscussionPostFragment on DiscussionPost {\n  id\n  text\n  json\n  createdAt\n  media {\n    ...PostCardMediaFragment\n  }\n  author {\n    id\n    fullName\n    username\n    avatar {\n      key\n    }\n  }\n}\n": types.DiscussionPostFragmentFragmentDoc,
    "\nquery DiscussionsQuery($discussionContextId: ID!, $limit: Int!, $nextToken: String) {\n  discussionFeed(discussionContextId: $discussionContextId, limit: $limit, nextToken: $nextToken) {\n    items {\n      ...DiscussionPostFragment\n    }\n    nextToken\n  }\n}\n": types.DiscussionsQueryDocument,
    "\nmutation AddDiscussionPost($input: DiscussionPostInput!) {\n  addDiscussionPost(input: $input) {\n    ...DiscussionPostFragment\n  }\n}\n": types.AddDiscussionPostDocument,
    "\n  mutation AddPost($input: RegularPostInput!) {\n    addPost(input: $input) {\n      ...PostCardFragment\n    }\n  }\n": types.AddPostDocument,
    "\n  fragment NotificationItemFragment on Notification {\n    read\n    createdAt\n    id\n    notificationUserId\n    inAppNotificationContent {\n      htmlTitle\n      htmlBody\n      jsonBody\n      image {\n        alt\n        href\n        file {\n          key\n          bucket\n          region\n          type\n        }\n      }\n      toggleFollowUserButton {\n        user {\n          id\n          following\n        }\n      }\n      bottomActions {\n        title\n        href\n      }\n    }\n  }\n": types.NotificationItemFragmentFragmentDoc,
    "\nquery OnboardingFollowAuthors {\n  onboardingFollowSuggestions {\n    ...FollowableUserCardFragment\n  }\n}\n": types.OnboardingFollowAuthorsDocument,
    "\n  mutation UpdateOnboardingProgress($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      protectedFields {\n        onboardingProgress\n      }\n    }\n  }\n": types.UpdateOnboardingProgressDocument,
    "\n  fragment BasePostFragment on RegularPost {\n    ...UnauthenticatedPostFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n    __typename\n  }\n": types.BasePostFragmentFragmentDoc,
    "\n  query PostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n": types.PostQueryDocument,
    "\nfragment PostCardAuthorFragment on User {\n  ...UnauthenticatedPostCardAuthorFragment\n  following\n}\n": types.PostCardAuthorFragmentFragmentDoc,
    "\n  fragment PostCardFragment on RegularPost {\n    ...BasePostFragment\n    quotedPost {\n      ... on RegularPost {\n        ...RegularPostPreviewFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on RePost {\n        id\n        originalPost {\n          ...RegularPostPreviewFragment\n        }\n      }\n    }\n  }\n": types.PostCardFragmentFragmentDoc,
    "\nfragment PostCardMediaFragment on S3Object {\n  key\n  type\n  width\n  height\n}\n": types.PostCardMediaFragmentFragmentDoc,
    "\n  fragment RegularPostPreviewFragment on RegularPost {\n    id\n    text\n    json\n    createdAt\n    deletedAt\n    releaseAt\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n": types.RegularPostPreviewFragmentFragmentDoc,
    "\nfragment RepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...PostCardFragment\n  }\n  author {\n    id\n    ...UserBadgeFragment\n    influenceScore\n    reputationScore\n    votePower\n    following\n  }\n  __typename\n}\n": types.RepostCardFragmentFragmentDoc,
    "\n  fragment UnauthenticatedPostFragment on RegularPost {\n    id\n    text\n    json\n    reputationBet\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n": types.UnauthenticatedPostFragmentFragmentDoc,
    "\nfragment UnauthenticatedPostCardAuthorFragment on User {\n  id\n  ...UserBadgeFragment\n  xComHandle\n  influenceScore\n  reputationScore\n  votePower\n}\n": types.UnauthenticatedPostCardAuthorFragmentFragmentDoc,
    "\n  mutation DeletePost($postId: ID!) {\n    deletePost(postId: $postId) {\n      ... on RegularPost {\n        id\n        deletedAt\n      }\n      ... on StreamPost {\n        id\n        deletedAt\n      }\n      ... on TournamentPost {\n        id\n        deletedAt\n      }\n      ... on RePost {\n        id\n        deletedAt\n      }\n    }\n  }\n": types.DeletePostDocument,
    "\n  mutation Repost($input: RePostInput!) {\n    repost(input: $input) {\n      ...RepostCardFragment\n    }\n  }\n": types.RepostDocument,
    "\n  mutation UnRepost($repostId: ID!) {\n    unRepost(repostId: $repostId)\n  }\n": types.UnRepostDocument,
    "\n  fragment RegularPostVotesFragment on RegularPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n": types.RegularPostVotesFragmentFragmentDoc,
    "\n  fragment StreamPostVotesFragment on StreamPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n": types.StreamPostVotesFragmentFragmentDoc,
    "\n  fragment TournamentPostVotesFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n": types.TournamentPostVotesFragmentFragmentDoc,
    "\n  fragment CommentPostVotesFragment on CommentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n": types.CommentPostVotesFragmentFragmentDoc,
    "\nmutation Vote($postId: ID!, $type: VoteType!) {\n  vote(id: $postId, vote: $type) {\n    ... on RegularPost {\n      ...RegularPostVotesFragment\n    }\n    ... on StreamPost {\n      ...StreamPostVotesFragment\n    }\n    ... on TournamentPost {\n      ...TournamentPostVotesFragment\n    }\n    ... on CommentPost {\n      ...CommentPostVotesFragment\n    }\n  }\n}\n": types.VoteDocument,
    "\n  mutation Unvote($postId: ID!) {\n    unvote(id: $postId) {\n      ... on RegularPost {\n        ...RegularPostVotesFragment\n      }\n      ... on StreamPost {\n        ...StreamPostVotesFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostVotesFragment\n      }\n      ... on CommentPost {\n        ...CommentPostVotesFragment\n      }\n    }\n  }\n": types.UnvoteDocument,
    "\nquery TypeaheadQuery($query: String!, $limit: Int = 3) {\n  typeahead(input: {text: $query, limit: $limit, type: [User]}) {\n    users {\n      id\n      ...UserBadgeFragment\n    }\n  }\n}\n": types.TypeaheadQueryDocument,
    "\n  mutation SetUsername($username: String!) {\n    setUsername(username: $username) {\n      id\n      username\n    }\n  }\n": types.SetUsernameDocument,
    "\nquery StreamTypeaheadQuery($query: String!, $limit: Int = 10, $offset: Int = 0) {\n  typeahead(input: {text: $query, limit: $limit, offset: $offset, type: [Stream]}) {\n    streams {\n      id\n      ...StreamCardFragment\n      accessAllowed\n    }\n    offset\n  }\n}\n": types.StreamTypeaheadQueryDocument,
    "\nfragment StreamCardFragment on Stream {\n  id\n  name\n  description\n  author {\n    id\n    username\n    fullName\n    avatar {\n      key\n    }\n  }\n  label\n  followersCount\n  streamCategory\n  isFeatured\n  streamState\n  monthlyPostFrequency\n  paidStreamData {\n    price\n  }\n  subscription {\n    cancelAt\n  }\n}\n": types.StreamCardFragmentFragmentDoc,
    "\nfragment BaseStreamPostCardFragment on StreamPost {\n  id\n  text\n  json\n  upvotes\n  downvotes\n  createdAt\n  releaseAt\n  released\n  deletedAt\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  media {\n    ...PostCardMediaFragment\n    __typename\n  }\n  __typename\n}\n": types.BaseStreamPostCardFragmentFragmentDoc,
    "\nfragment StreamPostCardFragment on StreamPost {\n  ...BaseStreamPostCardFragment\n  author {\n    ...PostCardAuthorFragment\n    __typename\n  }\n  stream {\n    id\n    label\n    isEmpty\n    paidStreamData {\n      price\n    }\n    __typename\n  }\n  voted\n  __typename\n}\n": types.StreamPostCardFragmentFragmentDoc,
    "\n  mutation AddStreamPost($input: StreamPostInput!) {\n    addStreamPost(input: $input) {\n      ...StreamPostCardFragment\n    }\n  }\n": types.AddStreamPostDocument,
    "\n  query stripeOnboardingUrlQuery($page: OnboardingLinkPage, $queryParams: String) {\n    me {\n      protectedFields {\n        stripeSellerAccount {\n          onboardingUrl(page: $page, queryParams: $queryParams)\n        }\n      }\n    }\n  }\n": types.StripeOnboardingUrlQueryDocument,
    "\nfragment StripeSellerProfileFragment on StripeSellerAccount {\n  id\n  props {\n    chargesEnabled\n    payoutsEnabled\n    detailsSubmitted\n    documentsRequired\n  }\n}\n": types.StripeSellerProfileFragmentFragmentDoc,
    "\n  mutation CreateStripeSellerAccount {\n    createStripeSellerAccount {\n      __typename\n      ...StripeSellerProfileFragment\n    }\n  }\n": types.CreateStripeSellerAccountDocument,
    "\n  query StreamCheckoutLinkQuery($streamId: ID!) {\n    streamSubscriptionCheckout(streamId: $streamId) {\n      url\n    }\n  }\n": types.StreamCheckoutLinkQueryDocument,
    "\nquery StreamAccessQuery($id: ID!) {\n  stream(id: $id) {\n    accessAllowed\n  }\n}\n": types.StreamAccessQueryDocument,
    "\n  mutation CancelStreamSubscription($input: StreamSubscriptionCancelInput!) {\n    streamSubscriptionCancel(input: $input) {\n      cancelAt\n    }\n  }\n": types.CancelStreamSubscriptionDocument,
    "\n  mutation AddStream($input: StreamInput!) {\n    addStream(input: $input) {\n      ...StreamCardFragment\n    }\n  }\n": types.AddStreamDocument,
    "\n  mutation DeleteStream($id: ID!) {\n    deleteStream(id: $id)\n  }\n": types.DeleteStreamDocument,
    "\n  mutation PublishStream($id: ID!) {\n    publishStream(id: $id) {\n      id\n      streamState\n    }\n  }\n": types.PublishStreamDocument,
    "\n  mutation UpdateStream($id: ID!, $input: UpdateStreamInput!) {\n    updateStream(id: $id, input: $input) {\n      id\n      name\n      description\n      label\n      streamCategory\n      monthlyPostFrequency\n    }\n  }\n": types.UpdateStreamDocument,
    "\n  mutation Follow($userId: ID!) {\n    follow(userId: $userId)\n  }\n": types.FollowDocument,
    "\n  mutation Unfollow($userId: ID!) {\n    unfollow(userId: $userId)\n  }\n": types.UnfollowDocument,
    "\nfragment UserFollowingFragment on User {\n  following\n}": types.UserFollowingFragmentFragmentDoc,
    "\nfragment UserFollowingCountFragment on User {\n  followingCount\n  followersCount\n}\n": types.UserFollowingCountFragmentFragmentDoc,
    "\n  fragment BaseTournamentPostCardFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    tournament {\n      ...TournamentPostTournamentFragment\n    }\n    fields {\n      name\n      value\n    }\n    __typename\n  }\n": types.BaseTournamentPostCardFragmentFragmentDoc,
    "\n  fragment TournamentPostCardFragment on TournamentPost {\n    ...BaseTournamentPostCardFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n  }\n": types.TournamentPostCardFragmentFragmentDoc,
    "\nfragment TournamentPostTournamentFragment on Tournament {\n  id\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}": types.TournamentPostTournamentFragmentFragmentDoc,
    "\nfragment TournamentFieldsFragment on FieldDefinition {\n  name\n  type\n}\n": types.TournamentFieldsFragmentFragmentDoc,
    "\n  query UnauthenticatedPostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n": types.UnauthenticatedPostQueryDocument,
    "\nfragment UnauthenticatedRegularPostCardFragment on RegularPost {\n  ...UnauthenticatedPostFragment\n  quotedPost {\n    ... on RegularPost {\n      ...RegularPostPreviewFragment\n    }\n    ... on StreamPost {\n      ...BaseStreamPostCardFragment\n    }\n    ... on TournamentPost {\n      ...BaseTournamentPostCardFragment\n    }\n    ... on RePost {\n      id\n      originalPost {\n        ...RegularPostPreviewFragment\n      }\n    }\n  }\n}\n": types.UnauthenticatedRegularPostCardFragmentFragmentDoc,
    "\nfragment UnauthenticatedRepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...UnauthenticatedRegularPostCardFragment\n  }\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  __typename\n}\n": types.UnauthenticatedRepostCardFragmentFragmentDoc,
    "\n  mutation UpdateAvatar($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      avatar {\n        key\n      }\n    }\n  }\n": types.UpdateAvatarDocument,
    "\n  mutation UpdateBackgroundImage($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      backgroundImage {\n        key\n      }\n    }\n  }\n": types.UpdateBackgroundImageDocument,
    "\nfragment UserBadgeFragment on User {\n  id\n  fullName\n  username\n  avatar {\n    key\n  }\n}\n": types.UserBadgeFragmentFragmentDoc,
    "\nfragment FollowableUserCardFragment on User {\n  id\n  following\n  ...UserCardFragment\n}\n": types.FollowableUserCardFragmentFragmentDoc,
    "\nfragment UserCardFragment on User {\n  id\n  ...UserBadgeFragment\n}\n": types.UserCardFragmentFragmentDoc,
    "\n  fragment VerificationsFragment on User {\n    verifications {\n      passportKYC\n      wallet\n      xCom\n    }\n  }\n": types.VerificationsFragmentFragmentDoc,
    "\n  query meUsernameQuery {\n    me {\n      username\n    }\n}\n": types.MeUsernameQueryDocument,
    "\n  query ProfilePostsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    user(username: $username) {\n      id\n      posts(limit:  $limit, nextToken: $nextToken) {\n        items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n      }\n        nextToken\n      }\n    }\n  }\n": types.ProfilePostsQueryDocument,
    "\n  query ProfileStreamsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    userStreams(username: $username, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...StreamCardFragment\n      }\n      nextToken\n    }\n  }\n": types.ProfileStreamsQueryDocument,
    "\n  query UserStatisticsQuery($username: String!) {\n    user(username: $username) {\n      id\n      ...MainStatisticProfileFragment\n      ...ReputationSectionFragment\n      ...InfluenceSectionFragment\n      ...UserTournamentsSection\n    }\n  }\n": types.UserStatisticsQueryDocument,
    "\n  fragment InfluenceSectionFragment on User {\n    influenceScore\n    closenessScore\n    betweennessScore\n    eigenvectorScore\n    pagerankScore\n  }\n": types.InfluenceSectionFragmentFragmentDoc,
    "\n  fragment ReputationScoreHistoryFragment on UserScoreHistoryItem {\n    datetime\n    score\n  }\n": types.ReputationScoreHistoryFragmentFragmentDoc,
    "\n  fragment ReputationSectionFragment on User {\n    reputationScore\n    totalUpvotesReceived\n    totalDownvotesReceived\n    averageReputationBet\n  }\n": types.ReputationSectionFragmentFragmentDoc,
    "\n  fragment UserTournamentsSection on User {\n    medals {\n      medal {\n        ...AwardFragment\n      }\n      tournaments{\n        id\n        name\n      }\n    }\n  }\n": types.UserTournamentsSectionFragmentDoc,
    "\n  query ReputationScoreHistoryQuery($username: String!, $limit: Int!, $resolution: ScoreHistoryResolution!) {\n    user(username: $username) {\n      id\n      reputationScoresHistory(limit: $limit, resolution: $resolution) {\n        items {\n          ...ReputationScoreHistoryFragment\n        }\n        resolution\n      }\n    }\n  }\n": types.ReputationScoreHistoryQueryDocument,
    "\n  mutation UpdateUserProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      fullName\n      avatar {\n        key\n      }\n      backgroundImage {\n        key\n      }\n      bio\n      xComHandle\n    }\n  }\n": types.UpdateUserProfileDocument,
    "\n  fragment MainStatisticProfileFragment on User {\n    following\n    username\n    fullName\n    avatar {\n      key\n    }\n    backgroundImage {\n      key\n    }\n    bio\n    xComHandle\n    followingCount\n    followersCount\n    reputationScore\n    subscribedToUserPosts\n    influenceScore\n    votePower\n    medals {\n      medal {\n        ...AwardFragment\n      }\n    }\n  }\n": types.MainStatisticProfileFragmentFragmentDoc,
    "\n  mutation SubscribeToUserPosts($userId: ID!) {\n    subscribeToUserPosts(userId: $userId)\n  }\n": types.SubscribeToUserPostsDocument,
    "\n  mutation UnsubscribeFromUserPosts($userId: ID!) {\n    unsubscribeFromUserPosts(userId: $userId)\n  }\n": types.UnsubscribeFromUserPostsDocument,
    "\nquery FollowersQuery($limit: Int, $nextToken: String, $username: String!, $isInfluential: Boolean!) {\n  followersByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n    isInfluential: $isInfluential\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n": types.FollowersQueryDocument,
    "\nquery FollowingQuery($limit: Int, $nextToken: String, $username: String!) {\n  followingByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n": types.FollowingQueryDocument,
    "\nsubscription OnFeedUpdated($feedScope: FeedScope!) {\n  feedUpdates(feedScope: $feedScope) {\n    __typename\n    post {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on RePost {\n       ...RepostCardFragment\n      }\n      ... on TournamentPost {\n       ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n       ...StreamPostCardFragment\n      }\n    }\n  }\n}\n": types.OnFeedUpdatedDocument,
    "\n  query FollowingFeedQuery($limit: Int!, $nextToken: String, $scope: FeedScope!) {\n    feed(limit: $limit, nextToken: $nextToken, scope: $scope) {\n      items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n        ... on StreamPost {\n         ...StreamPostCardFragment\n        }\n      }\n      nextToken\n    }\n  }\n": types.FollowingFeedQueryDocument,
    "\n  fragment InviteCodesFragment on InviteCode {\n    id\n    code\n    createdAt\n    updatedAt\n    creatorId\n    userId\n    referrer\n    invitedUser {\n      id\n      fullName\n      username\n    }\n    creator {\n      id\n      fullName\n      username\n    }\n  }\n": types.InviteCodesFragmentFragmentDoc,
    "\nquery AdminInvitesQuery($limit: Int!, $nextToken: String) {\n    inviteCodes(limit: $limit, nextToken: $nextToken) {\n    items {\n      ...InviteCodesFragment\n    }\n    nextToken\n  }\n}\n": types.AdminInvitesQueryDocument,
    "\n  mutation AddInviteCodes($count: Int!) {\n    addInviteCodes(count: $count) {\n      ...InviteCodesFragment\n    }\n  }\n": types.AddInviteCodesDocument,
    "\nquery AdminTournamentsQuery($limit: Int!, $nextToken: String, $state: TournamentState) {\n  tournaments(limit: $limit, nextToken: $nextToken, state: $state) {\n    items {\n      id\n      name\n\n    }\n    nextToken\n  }\n}\n": types.AdminTournamentsQueryDocument,
    "\nmutation AddTournament($input: TournamentInput!) {\n  addTournament(input: $input) {\n    ...TournamentFragment\n  }\n}\n": types.AddTournamentDocument,
    "\nfragment TournamentFragment on Tournament {\n  id\n  state\n  name\n}\n": types.TournamentFragmentFragmentDoc,
    "\nquery EditTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    description\n    deadline\n    rules\n    label\n    image {\n      key\n    }\n    fields {\n      ...TournamentFieldsFragment\n    }\n    category\n    createdAt\n    state\n    awards {\n      medalId\n      userId\n    }\n    listPriority\n    medals {\n      ...MedalCardFragment\n    }\n    participants(limit: 100) {\n      items {\n        user {\n          id\n          username\n          fullName\n        }\n      }\n      nextToken\n    }\n    submissionsCount\n  }\n}\n": types.EditTournamentQueryDocument,
    "\nquery MedalsQuery {\n  medals(limit: 100) {\n    items {\n      id\n      name\n      description\n      image {\n        key\n      }\n    }\n  }\n}\n": types.MedalsQueryDocument,
    "\nfragment MedalCardFragment on Medal {\n  id\n  name\n  description\n  upvotesReward\n  votePowerReward\n  durationDays\n  image {\n    key\n  }\n  money\n}\n": types.MedalCardFragmentFragmentDoc,
    "\n  mutation AddMedalToTournament($tournamentId: ID!, $medalId: ID!) {\n    addMedalToTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...AddMedalToTournamentFragment\n    }\n  }\n  ": types.AddMedalToTournamentDocument,
    "\n  fragment AddMedalToTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  ": types.AddMedalToTournamentFragmentFragmentDoc,
    "\nmutation AddMedal($input: MedalInput!) {\n  addMedal(input: $input) {\n    ...MedalCardFragment\n  }\n}\n": types.AddMedalDocument,
    "\n  mutation DeleteMedalFromTournament($tournamentId: ID!, $medalId: ID!) {\n    deleteMedalFromTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...DeleteMedalFromTournamentFragment\n    }\n  }\n  ": types.DeleteMedalFromTournamentDocument,
    "\n  fragment DeleteMedalFromTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  ": types.DeleteMedalFromTournamentFragmentFragmentDoc,
    "\nmutation UpdateTournament($id: ID!, $input: TournamentInput!) {\n  updateTournament(id: $id, input: $input) {\n    ...UpdateTournamentFragment\n  }\n}\n": types.UpdateTournamentDocument,
    "\nfragment UpdateTournamentFragment on Tournament {\n  id\n  state\n  label\n  category\n  name\n  headline\n  description\n  rules\n  deadline\n  fields {\n    type\n    name\n  }\n  awards {\n    medalId\n    userId\n  }\n  image {\n    key\n  }\n  listPriority\n}\n": types.UpdateTournamentFragmentFragmentDoc,
    "\nquery UserInviteCodesQuery {\n  me {\n    protectedFields {\n      inviteCodes {\n        ...UserInviteCodeFragment\n      }\n    }\n  }\n}\n": types.UserInviteCodesQueryDocument,
    "\n  mutation AddUserInviteCode {\n    addInviteCode {\n      ...UserInviteCodeFragment\n    }\n  }\n": types.AddUserInviteCodeDocument,
    "\n  fragment UserInviteCodeFragment on InviteCodeV2 {\n    code\n    createdAt\n    id\n    invitedUser {\n      id\n      ...UserBadgeFragment\n    }\n  }\n": types.UserInviteCodeFragmentFragmentDoc,
    "\nfragment LeaderCardFragment on LeaderbordItem {\n  user {\n    ...UserCardFragment\n  }\n  score\n}\n": types.LeaderCardFragmentFragmentDoc,
    "\nquery LeaderboardQuery($type: LeaderboardType!) {\n  leaderboard(type: $type) {\n    ...LeaderCardFragment\n  }\n}\n": types.LeaderboardQueryDocument,
    "\n  query NotificationsQuery($limit: Int!, $nextToken: String) {\n    notifications(limit: $limit, nextToken: $nextToken) {\n      items {\n        ...NotificationItemFragment\n      }\n      nextToken\n    }\n  }\n": types.NotificationsQueryDocument,
    "\n  subscription OnNotificationsUpdated {\n    onNotification {\n      ...NotificationItemFragment\n    }\n  }\n": types.OnNotificationsUpdatedDocument,
    "\n  mutation ReadNotification($notificationId: ID!) {\n    readNotification(id: $notificationId) {\n      ...NotificationItemFragment\n    }\n  }\n": types.ReadNotificationDocument,
    "\n  fragment CommentFragment on CommentPost {\n    id\n    json\n    createdAt\n    voteType\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      id\n      fullName\n      username\n      avatar {\n        key\n      }\n    }\n    upvotes\n    downvotes\n    voted\n    commentLevel\n    commentCount\n    parentPostId\n    __typename\n  }\n": types.CommentFragmentFragmentDoc,
    "query CommentsQuery($postId: ID!, $limit: Int!, $nextToken: String, $voteType: VoteTypeFilter, $orderBy: CommentOrderBy, $order: Order) {\ncomments(parentPostId: $postId, limit: $limit, nextToken: $nextToken, voteType: $voteType, orderBy: $orderBy, order: $order) {\n  items {\n    ...CommentFragment\n  }\n  nextToken\n}\n}": types.CommentsQueryDocument,
    "\n  mutation AddComment($input: CommentPostInput!) {\n    addComment(input: $input) {\n      ...CommentFragment\n    }\n  }\n": types.AddCommentDocument,
    "\nfragment RegularPostCommentCountFragment on RegularPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n": types.RegularPostCommentCountFragmentFragmentDoc,
    "\nfragment TournamentPostCommentCountFragment on TournamentPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n": types.TournamentPostCommentCountFragmentFragmentDoc,
    "\n  fragment CommentPostCommentCountFragment on CommentPost {\n    id\n    commentCount\n    __typename\n  }\n": types.CommentPostCommentCountFragmentFragmentDoc,
    "\n  query UnauthenticatedPostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on RePost {\n        ...UnauthenticatedRepostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n": types.UnauthenticatedPostPageQueryDocument,
    "\n  query PostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...PostCardFragment\n      }\n      ... on RePost {\n        originalPost {\n          upvoteCommentCount\n          downvoteCommentCount\n        }\n\n        ...RepostCardFragment\n      }\n      ... on TournamentPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n": types.PostPageQueryDocument,
    "query CommentByIdQuery($id: ID!) {\ncomment(id: $id) {\n  ...CommentFragment\n}\n}": types.CommentByIdQueryDocument,
    "\nfragment SearchResultsFragment on SearchResultPage {\n  items {\n    __typename\n    id\n    ... on RegularPost {\n      ...PostCardFragment\n    }\n    ... on User {\n      ...FollowableUserCardFragment\n    }\n    ... on Tournament {\n      ...TournamentCardFragment\n    }\n    ... on Stream {\n      ...StreamCardFragment\n    }\n  }\n  offset\n}\n": types.SearchResultsFragmentFragmentDoc,
    "\nquery SearchQuery($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n}\n": types.SearchQueryDocument,
    "\nquery SearchQueryWithItemsPreview($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n  searchPeoplePreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [User]}}) {\n    ...SearchResultsFragment\n  }\n  searchTournamentsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Tournament]}}) {\n    ...SearchResultsFragment\n  }\n  searchStreamsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Stream]}}) {\n    ...SearchResultsFragment\n  }\n}\n": types.SearchQueryWithItemsPreviewDocument,
    "\n  fragment StreamPublisherApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n    streamName\n    streamId\n  }\n": types.StreamPublisherApiKeyFragmentFragmentDoc,
    "\n  fragment StreamReaderApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n  }\n": types.StreamReaderApiKeyFragmentFragmentDoc,
    "\n  query ApiKeysQuery {\n    me {\n      id\n      protectedFields {\n        streamPublisherApiKeys {\n          ...StreamPublisherApiKeyFragment\n        }\n        streamReaderApiKeys {\n          ...StreamReaderApiKeyFragment\n        }\n      }\n    }\n  }\n": types.ApiKeysQueryDocument,
    "\n  query ConnectedAccountsQuery {\n    me {\n      id\n      xComHandle\n    }\n}\n": types.ConnectedAccountsQueryDocument,
    "\nquery SettingsNotifications {\n  settings {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n": types.SettingsNotificationsDocument,
    "\n  mutation AddPushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    addPushNotificationsSubscription(input: $input)\n  }\n": types.AddPushNotificationsSubscriptionDocument,
    "\n  mutation RemovePushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    removePushNotificationsSubscription(input: $input)\n  }\n": types.RemovePushNotificationsSubscriptionDocument,
    "\nmutation UpdateGeneralNotificationSettings($input: UpdateGeneralNotificationSettingsInput!) {\n  updateGeneralNotificationSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n": types.UpdateGeneralNotificationSettingsDocument,
    "\nmutation UpdateNotificationSettings($input: UpdateSettingsInput!) {\n  updateSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n": types.UpdateNotificationSettingsDocument,
    "\n  query PersonalDetailsQuery {\n    me {\n      id\n      username\n      protectedFields {\n        email\n      }\n      xComHandle\n    }\n}\n": types.PersonalDetailsQueryDocument,
    "\n  subscription OnCustomCollectionPostUpdate($collectionId: ID!) {\n    onCustomCollectionPostUpdate(collectionId: $collectionId) {\n      post {\n        ...CustomCollectionPostFragment\n      }\n    }\n  }\n": types.OnCustomCollectionPostUpdateDocument,
    "\n  fragment CustomCollectionPostFragment on StreamPost {\n    ...StreamPostCardFragment\n  }\n": types.CustomCollectionPostFragmentFragmentDoc,
    "\n  fragment CustomCollectionSourceFragment on Stream {\n    id\n    name\n    description\n  }\n": types.CustomCollectionSourceFragmentFragmentDoc,
    "\n  fragment CustomCollectionFragment on CustomCollection {\n    id\n    name\n    sources {\n      ...CustomCollectionSourceFragment\n    }\n    filters {\n      excludeKeywords {\n        all\n        any\n        phrase\n      }\n      includeKeywords {\n        all\n        any\n        phrase\n      }\n    }\n  }\n": types.CustomCollectionFragmentFragmentDoc,
    "\n  query GetCustomCollections {\n    customCollections {\n      ...CustomCollectionFragment\n    }\n  }\n": types.GetCustomCollectionsDocument,
    "\n  mutation AddCustomCollection($input: AddCustomCollectionInput!) {\n    addCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n": types.AddCustomCollectionDocument,
    "\n  query GetCustomCollectionPosts($collectionId: ID!, $limit: Int, $nextToken: String) {\n    customCollectionPosts(collectionId: $collectionId, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...CustomCollectionPostFragment\n      }\n      nextToken\n    }\n  }\n": types.GetCustomCollectionPostsDocument,
    "\n  mutation UpdateCustomCollection($input: UpdateCustomCollectionInput!) {\n    updateCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n": types.UpdateCustomCollectionDocument,
    "\n  mutation RemoveCustomCollection($id: ID!) {\n    removeCustomCollection(id: $id)\n  }\n": types.RemoveCustomCollectionDocument,
    "\n  mutation UpdateCustomCollectionSortOrder($sortOrder: [String!]!) {\n    updateCustomCollectionSortOrder(sortOrder: $sortOrder)\n  }\n": types.UpdateCustomCollectionSortOrderDocument,
    "\nquery StreamsQuery($queryType: StreamQueryType!, $limit: Int!, $nextToken: String, $streamCategory: StreamCategory) {\n  streams(queryType: $queryType, limit: $limit, nextToken: $nextToken, streamCategory: $streamCategory) {\n    items {\n      ...StreamCardFragment\n    }\n    nextToken\n  }\n}\n": types.StreamsQueryDocument,
    "\nquery StreamSearchQuery($query: String!, $offset: Int!, $streamCategory: StreamCategory) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Stream], stream: {streamCategory: $streamCategory}}}) {\n    items {\n      ...StreamCardFragment\n    }\n    offset\n  }\n}\n": types.StreamSearchQueryDocument,
    "\nsubscription OnStreamPostsUpdated($streamId: ID!) {\n  streamPostsUpdates(streamId: $streamId) {\n    ...StreamPostCardFragment\n  }\n}\n": types.OnStreamPostsUpdatedDocument,
    "\nquery StreamQuery($id: ID!) {\n  stream(id: $id) {\n    id\n    accessAllowed\n    author {\n      id\n      username\n    }\n    name\n    description\n    following\n    label\n    upvotes\n    downvotes\n    streamCategory\n    monthlyPostFrequency\n    streamState\n    isEmpty\n    protectedFields {\n      streamApiKey {\n        id\n        secretHint\n      }\n    }\n    paidStreamData {\n      price\n      sourceOfData\n    }\n    subscription {\n      cancelAt\n    }\n  }\n}\n": types.StreamQueryDocument,
    "\nquery StreamPostsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  streamPosts(id: $id, limit: $limit, nextToken: $nextToken) {\n    items {\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n    nextToken\n  }\n}\n": types.StreamPostsQueryDocument,
    "\n  query UnauthenticatedStreamQuery($id: ID!) {\n    stream(id: $id) {\n      id\n      author {\n        id\n        username\n        xComHandle\n      }\n      name\n      description\n      label\n      upvotes\n      downvotes\n    }\n  }\n": types.UnauthenticatedStreamQueryDocument,
    "\n  mutation FollowStream($streamId: ID!) {\n    followStream(streamId: $streamId)\n  }\n": types.FollowStreamDocument,
    "\n  mutation UnfollowStream($streamId: ID!) {\n    unfollowStream(streamId: $streamId)\n  }\n": types.UnfollowStreamDocument,
    "\nfragment StreamFollowingFragment on Stream {\n  following\n}": types.StreamFollowingFragmentFragmentDoc,
    "\nquery TournamentsSearchQuery($query: String!, $offset: Int!, $category: String, $closed: Boolean = null) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Tournament], tournament: {category: $category, closed: $closed}}}) {\n    items {\n      ... on Tournament {\n        ...TournamentCardFragment\n      }\n    }\n    offset\n  }\n}\n": types.TournamentsSearchQueryDocument,
    "\nfragment TournamentCardFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  headline\n  category\n  medals {\n    money\n  }\n  ...TournamentStatusFragment\n}\n": types.TournamentCardFragmentFragmentDoc,
    "\nfragment TournamentOverviewFragment on Tournament {\n  id\n  description\n  rules\n  state\n  label\n  participants(limit: 5, nextToken: null) {\n    items {\n      id\n      submissionsCount\n      user {\n        id\n        fullName\n        avatar {\n          key\n        }\n      }\n    }\n    nextToken\n  }\n  participantsCount\n  medals {\n   ...MedalCardFragment\n  }\n  deadline\n  mySubmissions {\n    id\n  }\n}\n": types.TournamentOverviewFragmentFragmentDoc,
    "\nquery TournamentParticipantsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    participants(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        user{\n          ...UserCardFragment\n        }\n        submissionsCount\n      }\n      nextToken\n    }\n  }\n}\n": types.TournamentParticipantsQueryDocument,
    "\nfragment TournamentAwardsFragment on Tournament {\n  state\n  awards {\n    medalId\n    user {\n      ...UserCardFragment\n    }\n  }\n}\n": types.TournamentAwardsFragmentFragmentDoc,
    "\nfragment TournamentMySubmissionsFragment on Tournament {\nstate\nmySubmissions {\n  ...TournamentPostCardFragment\n}\n}\n": types.TournamentMySubmissionsFragmentFragmentDoc,
    "\n  fragment BaseTournamentPostFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n  }\n": types.BaseTournamentPostFragmentFragmentDoc,
    "\nquery TournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    ...TournamentBannerFragment\n    ...TournamentMySubmissionsFragment\n    ...TournamentOverviewFragment\n    ...TournamentAwardsFragment\n    ...TournamentPostFormFragment\n  }\n}\n": types.TournamentQueryDocument,
    "\nquery TournamentSubmissionsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    submissions(limit: $limit, nextToken: $nextToken) {\n      items {\n          ...TournamentPostCardFragment\n      }\n      nextToken\n    }\n  }\n}\n": types.TournamentSubmissionsQueryDocument,
    "\nfragment TournamentPostFormFragment on Tournament {\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}\n": types.TournamentPostFormFragmentFragmentDoc,
    "\nfragment TournamentBannerFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  medals {\n    ...MedalCardFragment\n  }\n  headline\n  category\n  ...TournamentStatusFragment\n}\n": types.TournamentBannerFragmentFragmentDoc,
    "\nfragment TournamentStatusFragment on Tournament {\n  state\n  participantsCount\n  deadline\n}\n": types.TournamentStatusFragmentFragmentDoc,
    "\nsubscription OnTournamentSubmissions($tournamentId: ID!) {\n  tournamentPostsUpdates(tournamentId: $tournamentId) {\n    ...TournamentPostCardFragment\n  }\n}\n": types.OnTournamentSubmissionsDocument,
    "\nquery UnauthenticatedTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    image {\n     key\n    }\n    medals {\n      ...MedalCardFragment\n    }\n    category\n    ...TournamentStatusFragment\n  }\n}\n": types.UnauthenticatedTournamentQueryDocument,
    "\n  mutation AddTournamentPost($input: TournamentPostInput!) {\n    addTournamentPost(input: $input) {\n      ...TournamentPostCardFragment\n    }\n  }\n": types.AddTournamentPostDocument,
    "\nquery ActiveTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments(limit: $limit, nextToken: $nextToken, category: $category, state: ACTIVE) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n": types.ActiveTournamentsQueryDocument,
    "\nquery ClosedTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments: closedTournaments(limit: $limit, nextToken: $nextToken, category: $category) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n": types.ClosedTournamentsQueryDocument,
    "\n  query UnreadNotificationsQuery($limit: Int!, $read: Boolean!) {\n    notifications(read: $read, limit: $limit, nextToken: null) {\n      items {\n        id\n      }\n    }\n  }\n": types.UnreadNotificationsQueryDocument,
    "\n  query meQuery {\n    me {\n      id\n      username\n      fullName\n      createdAt\n      bio\n      protectedFields {\n        email\n        isOnboardingFinished\n        onboardingProgress\n        interests\n        possibleUsernames\n        intercomHmac\n        stripeSellerAccount {\n          ...StripeSellerProfileFragment\n        }\n      }\n      avatar {\n        key\n      }\n    }\n  }\n": types.MeQueryDocument,
    "\n  query flagsQuery {\n    flags\n  }\n": types.FlagsQueryDocument,
    "\nfragment MeMinimalFragment on User {\n  id\n  fullName\n  username\n}\n": types.MeMinimalFragmentFragmentDoc,
    "\n  query ChooseUsernameDataQuery {\n    me {\n      id\n      protectedFields {\n        possibleUsernames\n      }\n      xComHandle\n    }\n}\n": types.ChooseUsernameDataQueryDocument,
    "\n  mutation GenerateUsernames {\n    generatePossibleUsernames\n  }\n": types.GenerateUsernamesDocument,
    "\nquery PostPreviewQuery($id: ID!) {\n  post(id: $id) {\n    ... on RegularPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      reputationBet\n      __typename\n    }\n    ... on RePost {\n      id\n      originalPost {\n        id\n        json\n        author {\n          ...UserBadgeFragment\n        }\n        reputationBet\n        __typename\n      }\n      __typename\n    }\n    ... on TournamentPost {\n      id\n      fields {\n        name\n        value\n      }\n      author {\n        ...UserBadgeFragment\n      }\n      tournament {\n        ...TournamentPostTournamentFragment\n      }\n      __typename\n    }\n    ... on StreamPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      stream {\n        id\n        label\n      }\n      __typename\n    }\n  }\n}\n": types.PostPreviewQueryDocument,
    "\n  mutation RemoveXComHandle {\n    removeXComHandle {\n      xComHandle\n    }\n  }\n": types.RemoveXComHandleDocument,
    "\n  mutation SetXComHandle($code: String!) {\n    setXComHandle(code: $code) {\n      xComHandle\n      username\n    }\n  }\n": types.SetXComHandleDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation GenerateStreamPublisherApiKey($streamId: ID!) {\n  generateStreamPublisherApiKey(streamId: $streamId)\n}\n"): (typeof documents)["\nmutation GenerateStreamPublisherApiKey($streamId: ID!) {\n  generateStreamPublisherApiKey(streamId: $streamId)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation GenerateStreamReaderApiKey {\n  generateStreamReaderApiKey\n}\n"): (typeof documents)["\nmutation GenerateStreamReaderApiKey {\n  generateStreamReaderApiKey\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AwardFragment on Medal {\n    id\n    name\n    image {\n      key\n    }\n  }\n  "): (typeof documents)["\n  fragment AwardFragment on Medal {\n    id\n    name\n    image {\n      key\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment DiscussionPostFragment on DiscussionPost {\n  id\n  text\n  json\n  createdAt\n  media {\n    ...PostCardMediaFragment\n  }\n  author {\n    id\n    fullName\n    username\n    avatar {\n      key\n    }\n  }\n}\n"): (typeof documents)["\nfragment DiscussionPostFragment on DiscussionPost {\n  id\n  text\n  json\n  createdAt\n  media {\n    ...PostCardMediaFragment\n  }\n  author {\n    id\n    fullName\n    username\n    avatar {\n      key\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery DiscussionsQuery($discussionContextId: ID!, $limit: Int!, $nextToken: String) {\n  discussionFeed(discussionContextId: $discussionContextId, limit: $limit, nextToken: $nextToken) {\n    items {\n      ...DiscussionPostFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery DiscussionsQuery($discussionContextId: ID!, $limit: Int!, $nextToken: String) {\n  discussionFeed(discussionContextId: $discussionContextId, limit: $limit, nextToken: $nextToken) {\n    items {\n      ...DiscussionPostFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddDiscussionPost($input: DiscussionPostInput!) {\n  addDiscussionPost(input: $input) {\n    ...DiscussionPostFragment\n  }\n}\n"): (typeof documents)["\nmutation AddDiscussionPost($input: DiscussionPostInput!) {\n  addDiscussionPost(input: $input) {\n    ...DiscussionPostFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddPost($input: RegularPostInput!) {\n    addPost(input: $input) {\n      ...PostCardFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddPost($input: RegularPostInput!) {\n    addPost(input: $input) {\n      ...PostCardFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment NotificationItemFragment on Notification {\n    read\n    createdAt\n    id\n    notificationUserId\n    inAppNotificationContent {\n      htmlTitle\n      htmlBody\n      jsonBody\n      image {\n        alt\n        href\n        file {\n          key\n          bucket\n          region\n          type\n        }\n      }\n      toggleFollowUserButton {\n        user {\n          id\n          following\n        }\n      }\n      bottomActions {\n        title\n        href\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NotificationItemFragment on Notification {\n    read\n    createdAt\n    id\n    notificationUserId\n    inAppNotificationContent {\n      htmlTitle\n      htmlBody\n      jsonBody\n      image {\n        alt\n        href\n        file {\n          key\n          bucket\n          region\n          type\n        }\n      }\n      toggleFollowUserButton {\n        user {\n          id\n          following\n        }\n      }\n      bottomActions {\n        title\n        href\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery OnboardingFollowAuthors {\n  onboardingFollowSuggestions {\n    ...FollowableUserCardFragment\n  }\n}\n"): (typeof documents)["\nquery OnboardingFollowAuthors {\n  onboardingFollowSuggestions {\n    ...FollowableUserCardFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateOnboardingProgress($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      protectedFields {\n        onboardingProgress\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOnboardingProgress($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      protectedFields {\n        onboardingProgress\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BasePostFragment on RegularPost {\n    ...UnauthenticatedPostFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment BasePostFragment on RegularPost {\n    ...UnauthenticatedPostFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment PostCardAuthorFragment on User {\n  ...UnauthenticatedPostCardAuthorFragment\n  following\n}\n"): (typeof documents)["\nfragment PostCardAuthorFragment on User {\n  ...UnauthenticatedPostCardAuthorFragment\n  following\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PostCardFragment on RegularPost {\n    ...BasePostFragment\n    quotedPost {\n      ... on RegularPost {\n        ...RegularPostPreviewFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on RePost {\n        id\n        originalPost {\n          ...RegularPostPreviewFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PostCardFragment on RegularPost {\n    ...BasePostFragment\n    quotedPost {\n      ... on RegularPost {\n        ...RegularPostPreviewFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostCardFragment\n      }\n      ... on RePost {\n        id\n        originalPost {\n          ...RegularPostPreviewFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment PostCardMediaFragment on S3Object {\n  key\n  type\n  width\n  height\n}\n"): (typeof documents)["\nfragment PostCardMediaFragment on S3Object {\n  key\n  type\n  width\n  height\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RegularPostPreviewFragment on RegularPost {\n    id\n    text\n    json\n    createdAt\n    deletedAt\n    releaseAt\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment RegularPostPreviewFragment on RegularPost {\n    id\n    text\n    json\n    createdAt\n    deletedAt\n    releaseAt\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment RepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...PostCardFragment\n  }\n  author {\n    id\n    ...UserBadgeFragment\n    influenceScore\n    reputationScore\n    votePower\n    following\n  }\n  __typename\n}\n"): (typeof documents)["\nfragment RepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...PostCardFragment\n  }\n  author {\n    id\n    ...UserBadgeFragment\n    influenceScore\n    reputationScore\n    votePower\n    following\n  }\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UnauthenticatedPostFragment on RegularPost {\n    id\n    text\n    json\n    reputationBet\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment UnauthenticatedPostFragment on RegularPost {\n    id\n    text\n    json\n    reputationBet\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UnauthenticatedPostCardAuthorFragment on User {\n  id\n  ...UserBadgeFragment\n  xComHandle\n  influenceScore\n  reputationScore\n  votePower\n}\n"): (typeof documents)["\nfragment UnauthenticatedPostCardAuthorFragment on User {\n  id\n  ...UserBadgeFragment\n  xComHandle\n  influenceScore\n  reputationScore\n  votePower\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeletePost($postId: ID!) {\n    deletePost(postId: $postId) {\n      ... on RegularPost {\n        id\n        deletedAt\n      }\n      ... on StreamPost {\n        id\n        deletedAt\n      }\n      ... on TournamentPost {\n        id\n        deletedAt\n      }\n      ... on RePost {\n        id\n        deletedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePost($postId: ID!) {\n    deletePost(postId: $postId) {\n      ... on RegularPost {\n        id\n        deletedAt\n      }\n      ... on StreamPost {\n        id\n        deletedAt\n      }\n      ... on TournamentPost {\n        id\n        deletedAt\n      }\n      ... on RePost {\n        id\n        deletedAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Repost($input: RePostInput!) {\n    repost(input: $input) {\n      ...RepostCardFragment\n    }\n  }\n"): (typeof documents)["\n  mutation Repost($input: RePostInput!) {\n    repost(input: $input) {\n      ...RepostCardFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UnRepost($repostId: ID!) {\n    unRepost(repostId: $repostId)\n  }\n"): (typeof documents)["\n  mutation UnRepost($repostId: ID!) {\n    unRepost(repostId: $repostId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment RegularPostVotesFragment on RegularPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"): (typeof documents)["\n  fragment RegularPostVotesFragment on RegularPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StreamPostVotesFragment on StreamPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"): (typeof documents)["\n  fragment StreamPostVotesFragment on StreamPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TournamentPostVotesFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"): (typeof documents)["\n  fragment TournamentPostVotesFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CommentPostVotesFragment on CommentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"): (typeof documents)["\n  fragment CommentPostVotesFragment on CommentPost {\n    id\n    upvotes\n    downvotes\n    voted\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation Vote($postId: ID!, $type: VoteType!) {\n  vote(id: $postId, vote: $type) {\n    ... on RegularPost {\n      ...RegularPostVotesFragment\n    }\n    ... on StreamPost {\n      ...StreamPostVotesFragment\n    }\n    ... on TournamentPost {\n      ...TournamentPostVotesFragment\n    }\n    ... on CommentPost {\n      ...CommentPostVotesFragment\n    }\n  }\n}\n"): (typeof documents)["\nmutation Vote($postId: ID!, $type: VoteType!) {\n  vote(id: $postId, vote: $type) {\n    ... on RegularPost {\n      ...RegularPostVotesFragment\n    }\n    ... on StreamPost {\n      ...StreamPostVotesFragment\n    }\n    ... on TournamentPost {\n      ...TournamentPostVotesFragment\n    }\n    ... on CommentPost {\n      ...CommentPostVotesFragment\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Unvote($postId: ID!) {\n    unvote(id: $postId) {\n      ... on RegularPost {\n        ...RegularPostVotesFragment\n      }\n      ... on StreamPost {\n        ...StreamPostVotesFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostVotesFragment\n      }\n      ... on CommentPost {\n        ...CommentPostVotesFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Unvote($postId: ID!) {\n    unvote(id: $postId) {\n      ... on RegularPost {\n        ...RegularPostVotesFragment\n      }\n      ... on StreamPost {\n        ...StreamPostVotesFragment\n      }\n      ... on TournamentPost {\n        ...TournamentPostVotesFragment\n      }\n      ... on CommentPost {\n        ...CommentPostVotesFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TypeaheadQuery($query: String!, $limit: Int = 3) {\n  typeahead(input: {text: $query, limit: $limit, type: [User]}) {\n    users {\n      id\n      ...UserBadgeFragment\n    }\n  }\n}\n"): (typeof documents)["\nquery TypeaheadQuery($query: String!, $limit: Int = 3) {\n  typeahead(input: {text: $query, limit: $limit, type: [User]}) {\n    users {\n      id\n      ...UserBadgeFragment\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetUsername($username: String!) {\n    setUsername(username: $username) {\n      id\n      username\n    }\n  }\n"): (typeof documents)["\n  mutation SetUsername($username: String!) {\n    setUsername(username: $username) {\n      id\n      username\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamTypeaheadQuery($query: String!, $limit: Int = 10, $offset: Int = 0) {\n  typeahead(input: {text: $query, limit: $limit, offset: $offset, type: [Stream]}) {\n    streams {\n      id\n      ...StreamCardFragment\n      accessAllowed\n    }\n    offset\n  }\n}\n"): (typeof documents)["\nquery StreamTypeaheadQuery($query: String!, $limit: Int = 10, $offset: Int = 0) {\n  typeahead(input: {text: $query, limit: $limit, offset: $offset, type: [Stream]}) {\n    streams {\n      id\n      ...StreamCardFragment\n      accessAllowed\n    }\n    offset\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment StreamCardFragment on Stream {\n  id\n  name\n  description\n  author {\n    id\n    username\n    fullName\n    avatar {\n      key\n    }\n  }\n  label\n  followersCount\n  streamCategory\n  isFeatured\n  streamState\n  monthlyPostFrequency\n  paidStreamData {\n    price\n  }\n  subscription {\n    cancelAt\n  }\n}\n"): (typeof documents)["\nfragment StreamCardFragment on Stream {\n  id\n  name\n  description\n  author {\n    id\n    username\n    fullName\n    avatar {\n      key\n    }\n  }\n  label\n  followersCount\n  streamCategory\n  isFeatured\n  streamState\n  monthlyPostFrequency\n  paidStreamData {\n    price\n  }\n  subscription {\n    cancelAt\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment BaseStreamPostCardFragment on StreamPost {\n  id\n  text\n  json\n  upvotes\n  downvotes\n  createdAt\n  releaseAt\n  released\n  deletedAt\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  media {\n    ...PostCardMediaFragment\n    __typename\n  }\n  __typename\n}\n"): (typeof documents)["\nfragment BaseStreamPostCardFragment on StreamPost {\n  id\n  text\n  json\n  upvotes\n  downvotes\n  createdAt\n  releaseAt\n  released\n  deletedAt\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  media {\n    ...PostCardMediaFragment\n    __typename\n  }\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment StreamPostCardFragment on StreamPost {\n  ...BaseStreamPostCardFragment\n  author {\n    ...PostCardAuthorFragment\n    __typename\n  }\n  stream {\n    id\n    label\n    isEmpty\n    paidStreamData {\n      price\n    }\n    __typename\n  }\n  voted\n  __typename\n}\n"): (typeof documents)["\nfragment StreamPostCardFragment on StreamPost {\n  ...BaseStreamPostCardFragment\n  author {\n    ...PostCardAuthorFragment\n    __typename\n  }\n  stream {\n    id\n    label\n    isEmpty\n    paidStreamData {\n      price\n    }\n    __typename\n  }\n  voted\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddStreamPost($input: StreamPostInput!) {\n    addStreamPost(input: $input) {\n      ...StreamPostCardFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddStreamPost($input: StreamPostInput!) {\n    addStreamPost(input: $input) {\n      ...StreamPostCardFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query stripeOnboardingUrlQuery($page: OnboardingLinkPage, $queryParams: String) {\n    me {\n      protectedFields {\n        stripeSellerAccount {\n          onboardingUrl(page: $page, queryParams: $queryParams)\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query stripeOnboardingUrlQuery($page: OnboardingLinkPage, $queryParams: String) {\n    me {\n      protectedFields {\n        stripeSellerAccount {\n          onboardingUrl(page: $page, queryParams: $queryParams)\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment StripeSellerProfileFragment on StripeSellerAccount {\n  id\n  props {\n    chargesEnabled\n    payoutsEnabled\n    detailsSubmitted\n    documentsRequired\n  }\n}\n"): (typeof documents)["\nfragment StripeSellerProfileFragment on StripeSellerAccount {\n  id\n  props {\n    chargesEnabled\n    payoutsEnabled\n    detailsSubmitted\n    documentsRequired\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CreateStripeSellerAccount {\n    createStripeSellerAccount {\n      __typename\n      ...StripeSellerProfileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateStripeSellerAccount {\n    createStripeSellerAccount {\n      __typename\n      ...StripeSellerProfileFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query StreamCheckoutLinkQuery($streamId: ID!) {\n    streamSubscriptionCheckout(streamId: $streamId) {\n      url\n    }\n  }\n"): (typeof documents)["\n  query StreamCheckoutLinkQuery($streamId: ID!) {\n    streamSubscriptionCheckout(streamId: $streamId) {\n      url\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamAccessQuery($id: ID!) {\n  stream(id: $id) {\n    accessAllowed\n  }\n}\n"): (typeof documents)["\nquery StreamAccessQuery($id: ID!) {\n  stream(id: $id) {\n    accessAllowed\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation CancelStreamSubscription($input: StreamSubscriptionCancelInput!) {\n    streamSubscriptionCancel(input: $input) {\n      cancelAt\n    }\n  }\n"): (typeof documents)["\n  mutation CancelStreamSubscription($input: StreamSubscriptionCancelInput!) {\n    streamSubscriptionCancel(input: $input) {\n      cancelAt\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddStream($input: StreamInput!) {\n    addStream(input: $input) {\n      ...StreamCardFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddStream($input: StreamInput!) {\n    addStream(input: $input) {\n      ...StreamCardFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteStream($id: ID!) {\n    deleteStream(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteStream($id: ID!) {\n    deleteStream(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PublishStream($id: ID!) {\n    publishStream(id: $id) {\n      id\n      streamState\n    }\n  }\n"): (typeof documents)["\n  mutation PublishStream($id: ID!) {\n    publishStream(id: $id) {\n      id\n      streamState\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateStream($id: ID!, $input: UpdateStreamInput!) {\n    updateStream(id: $id, input: $input) {\n      id\n      name\n      description\n      label\n      streamCategory\n      monthlyPostFrequency\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStream($id: ID!, $input: UpdateStreamInput!) {\n    updateStream(id: $id, input: $input) {\n      id\n      name\n      description\n      label\n      streamCategory\n      monthlyPostFrequency\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Follow($userId: ID!) {\n    follow(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation Follow($userId: ID!) {\n    follow(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Unfollow($userId: ID!) {\n    unfollow(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation Unfollow($userId: ID!) {\n    unfollow(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserFollowingFragment on User {\n  following\n}"): (typeof documents)["\nfragment UserFollowingFragment on User {\n  following\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserFollowingCountFragment on User {\n  followingCount\n  followersCount\n}\n"): (typeof documents)["\nfragment UserFollowingCountFragment on User {\n  followingCount\n  followersCount\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BaseTournamentPostCardFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    tournament {\n      ...TournamentPostTournamentFragment\n    }\n    fields {\n      name\n      value\n    }\n    __typename\n  }\n"): (typeof documents)["\n  fragment BaseTournamentPostCardFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n    tournament {\n      ...TournamentPostTournamentFragment\n    }\n    fields {\n      name\n      value\n    }\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment TournamentPostCardFragment on TournamentPost {\n    ...BaseTournamentPostCardFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n  }\n"): (typeof documents)["\n  fragment TournamentPostCardFragment on TournamentPost {\n    ...BaseTournamentPostCardFragment\n    voted\n    repostId\n    author {\n      ...PostCardAuthorFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentPostTournamentFragment on Tournament {\n  id\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}"): (typeof documents)["\nfragment TournamentPostTournamentFragment on Tournament {\n  id\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentFieldsFragment on FieldDefinition {\n  name\n  type\n}\n"): (typeof documents)["\nfragment TournamentFieldsFragment on FieldDefinition {\n  name\n  type\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UnauthenticatedPostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query UnauthenticatedPostQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UnauthenticatedRegularPostCardFragment on RegularPost {\n  ...UnauthenticatedPostFragment\n  quotedPost {\n    ... on RegularPost {\n      ...RegularPostPreviewFragment\n    }\n    ... on StreamPost {\n      ...BaseStreamPostCardFragment\n    }\n    ... on TournamentPost {\n      ...BaseTournamentPostCardFragment\n    }\n    ... on RePost {\n      id\n      originalPost {\n        ...RegularPostPreviewFragment\n      }\n    }\n  }\n}\n"): (typeof documents)["\nfragment UnauthenticatedRegularPostCardFragment on RegularPost {\n  ...UnauthenticatedPostFragment\n  quotedPost {\n    ... on RegularPost {\n      ...RegularPostPreviewFragment\n    }\n    ... on StreamPost {\n      ...BaseStreamPostCardFragment\n    }\n    ... on TournamentPost {\n      ...BaseTournamentPostCardFragment\n    }\n    ... on RePost {\n      id\n      originalPost {\n        ...RegularPostPreviewFragment\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UnauthenticatedRepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...UnauthenticatedRegularPostCardFragment\n  }\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  __typename\n}\n"): (typeof documents)["\nfragment UnauthenticatedRepostCardFragment on RePost {\n  id\n  createdAt\n  originalPost {\n    ...UnauthenticatedRegularPostCardFragment\n  }\n  author {\n    ...UnauthenticatedPostCardAuthorFragment\n  }\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateAvatar($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      avatar {\n        key\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAvatar($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      avatar {\n        key\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateBackgroundImage($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      backgroundImage {\n        key\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateBackgroundImage($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      backgroundImage {\n        key\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserBadgeFragment on User {\n  id\n  fullName\n  username\n  avatar {\n    key\n  }\n}\n"): (typeof documents)["\nfragment UserBadgeFragment on User {\n  id\n  fullName\n  username\n  avatar {\n    key\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment FollowableUserCardFragment on User {\n  id\n  following\n  ...UserCardFragment\n}\n"): (typeof documents)["\nfragment FollowableUserCardFragment on User {\n  id\n  following\n  ...UserCardFragment\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UserCardFragment on User {\n  id\n  ...UserBadgeFragment\n}\n"): (typeof documents)["\nfragment UserCardFragment on User {\n  id\n  ...UserBadgeFragment\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment VerificationsFragment on User {\n    verifications {\n      passportKYC\n      wallet\n      xCom\n    }\n  }\n"): (typeof documents)["\n  fragment VerificationsFragment on User {\n    verifications {\n      passportKYC\n      wallet\n      xCom\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query meUsernameQuery {\n    me {\n      username\n    }\n}\n"): (typeof documents)["\n  query meUsernameQuery {\n    me {\n      username\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfilePostsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    user(username: $username) {\n      id\n      posts(limit:  $limit, nextToken: $nextToken) {\n        items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n      }\n        nextToken\n      }\n    }\n  }\n"): (typeof documents)["\n  query ProfilePostsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    user(username: $username) {\n      id\n      posts(limit:  $limit, nextToken: $nextToken) {\n        items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n      }\n        nextToken\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ProfileStreamsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    userStreams(username: $username, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...StreamCardFragment\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query ProfileStreamsQuery($username: String!, $limit: Int!, $nextToken: String) {\n    userStreams(username: $username, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...StreamCardFragment\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UserStatisticsQuery($username: String!) {\n    user(username: $username) {\n      id\n      ...MainStatisticProfileFragment\n      ...ReputationSectionFragment\n      ...InfluenceSectionFragment\n      ...UserTournamentsSection\n    }\n  }\n"): (typeof documents)["\n  query UserStatisticsQuery($username: String!) {\n    user(username: $username) {\n      id\n      ...MainStatisticProfileFragment\n      ...ReputationSectionFragment\n      ...InfluenceSectionFragment\n      ...UserTournamentsSection\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment InfluenceSectionFragment on User {\n    influenceScore\n    closenessScore\n    betweennessScore\n    eigenvectorScore\n    pagerankScore\n  }\n"): (typeof documents)["\n  fragment InfluenceSectionFragment on User {\n    influenceScore\n    closenessScore\n    betweennessScore\n    eigenvectorScore\n    pagerankScore\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReputationScoreHistoryFragment on UserScoreHistoryItem {\n    datetime\n    score\n  }\n"): (typeof documents)["\n  fragment ReputationScoreHistoryFragment on UserScoreHistoryItem {\n    datetime\n    score\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ReputationSectionFragment on User {\n    reputationScore\n    totalUpvotesReceived\n    totalDownvotesReceived\n    averageReputationBet\n  }\n"): (typeof documents)["\n  fragment ReputationSectionFragment on User {\n    reputationScore\n    totalUpvotesReceived\n    totalDownvotesReceived\n    averageReputationBet\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserTournamentsSection on User {\n    medals {\n      medal {\n        ...AwardFragment\n      }\n      tournaments{\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment UserTournamentsSection on User {\n    medals {\n      medal {\n        ...AwardFragment\n      }\n      tournaments{\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ReputationScoreHistoryQuery($username: String!, $limit: Int!, $resolution: ScoreHistoryResolution!) {\n    user(username: $username) {\n      id\n      reputationScoresHistory(limit: $limit, resolution: $resolution) {\n        items {\n          ...ReputationScoreHistoryFragment\n        }\n        resolution\n      }\n    }\n  }\n"): (typeof documents)["\n  query ReputationScoreHistoryQuery($username: String!, $limit: Int!, $resolution: ScoreHistoryResolution!) {\n    user(username: $username) {\n      id\n      reputationScoresHistory(limit: $limit, resolution: $resolution) {\n        items {\n          ...ReputationScoreHistoryFragment\n        }\n        resolution\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      fullName\n      avatar {\n        key\n      }\n      backgroundImage {\n        key\n      }\n      bio\n      xComHandle\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserProfile($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      id\n      fullName\n      avatar {\n        key\n      }\n      backgroundImage {\n        key\n      }\n      bio\n      xComHandle\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment MainStatisticProfileFragment on User {\n    following\n    username\n    fullName\n    avatar {\n      key\n    }\n    backgroundImage {\n      key\n    }\n    bio\n    xComHandle\n    followingCount\n    followersCount\n    reputationScore\n    subscribedToUserPosts\n    influenceScore\n    votePower\n    medals {\n      medal {\n        ...AwardFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MainStatisticProfileFragment on User {\n    following\n    username\n    fullName\n    avatar {\n      key\n    }\n    backgroundImage {\n      key\n    }\n    bio\n    xComHandle\n    followingCount\n    followersCount\n    reputationScore\n    subscribedToUserPosts\n    influenceScore\n    votePower\n    medals {\n      medal {\n        ...AwardFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SubscribeToUserPosts($userId: ID!) {\n    subscribeToUserPosts(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation SubscribeToUserPosts($userId: ID!) {\n    subscribeToUserPosts(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UnsubscribeFromUserPosts($userId: ID!) {\n    unsubscribeFromUserPosts(userId: $userId)\n  }\n"): (typeof documents)["\n  mutation UnsubscribeFromUserPosts($userId: ID!) {\n    unsubscribeFromUserPosts(userId: $userId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FollowersQuery($limit: Int, $nextToken: String, $username: String!, $isInfluential: Boolean!) {\n  followersByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n    isInfluential: $isInfluential\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery FollowersQuery($limit: Int, $nextToken: String, $username: String!, $isInfluential: Boolean!) {\n  followersByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n    isInfluential: $isInfluential\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery FollowingQuery($limit: Int, $nextToken: String, $username: String!) {\n  followingByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery FollowingQuery($limit: Int, $nextToken: String, $username: String!) {\n  followingByName(\n    limit: $limit\n    nextToken: $nextToken\n    username: $username\n  ) {\n    items {\n      ...FollowableUserCardFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnFeedUpdated($feedScope: FeedScope!) {\n  feedUpdates(feedScope: $feedScope) {\n    __typename\n    post {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on RePost {\n       ...RepostCardFragment\n      }\n      ... on TournamentPost {\n       ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n       ...StreamPostCardFragment\n      }\n    }\n  }\n}\n"): (typeof documents)["\nsubscription OnFeedUpdated($feedScope: FeedScope!) {\n  feedUpdates(feedScope: $feedScope) {\n    __typename\n    post {\n      ... on RegularPost {\n        ...PostCardFragment\n      }\n      ... on RePost {\n       ...RepostCardFragment\n      }\n      ... on TournamentPost {\n       ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n       ...StreamPostCardFragment\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query FollowingFeedQuery($limit: Int!, $nextToken: String, $scope: FeedScope!) {\n    feed(limit: $limit, nextToken: $nextToken, scope: $scope) {\n      items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n        ... on StreamPost {\n         ...StreamPostCardFragment\n        }\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query FollowingFeedQuery($limit: Int!, $nextToken: String, $scope: FeedScope!) {\n    feed(limit: $limit, nextToken: $nextToken, scope: $scope) {\n      items {\n        ... on RegularPost {\n          ...PostCardFragment\n        }\n        ... on RePost {\n         ...RepostCardFragment\n        }\n        ... on TournamentPost {\n         ...TournamentPostCardFragment\n        }\n        ... on StreamPost {\n         ...StreamPostCardFragment\n        }\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment InviteCodesFragment on InviteCode {\n    id\n    code\n    createdAt\n    updatedAt\n    creatorId\n    userId\n    referrer\n    invitedUser {\n      id\n      fullName\n      username\n    }\n    creator {\n      id\n      fullName\n      username\n    }\n  }\n"): (typeof documents)["\n  fragment InviteCodesFragment on InviteCode {\n    id\n    code\n    createdAt\n    updatedAt\n    creatorId\n    userId\n    referrer\n    invitedUser {\n      id\n      fullName\n      username\n    }\n    creator {\n      id\n      fullName\n      username\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery AdminInvitesQuery($limit: Int!, $nextToken: String) {\n    inviteCodes(limit: $limit, nextToken: $nextToken) {\n    items {\n      ...InviteCodesFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery AdminInvitesQuery($limit: Int!, $nextToken: String) {\n    inviteCodes(limit: $limit, nextToken: $nextToken) {\n    items {\n      ...InviteCodesFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddInviteCodes($count: Int!) {\n    addInviteCodes(count: $count) {\n      ...InviteCodesFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddInviteCodes($count: Int!) {\n    addInviteCodes(count: $count) {\n      ...InviteCodesFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery AdminTournamentsQuery($limit: Int!, $nextToken: String, $state: TournamentState) {\n  tournaments(limit: $limit, nextToken: $nextToken, state: $state) {\n    items {\n      id\n      name\n\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery AdminTournamentsQuery($limit: Int!, $nextToken: String, $state: TournamentState) {\n  tournaments(limit: $limit, nextToken: $nextToken, state: $state) {\n    items {\n      id\n      name\n\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddTournament($input: TournamentInput!) {\n  addTournament(input: $input) {\n    ...TournamentFragment\n  }\n}\n"): (typeof documents)["\nmutation AddTournament($input: TournamentInput!) {\n  addTournament(input: $input) {\n    ...TournamentFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentFragment on Tournament {\n  id\n  state\n  name\n}\n"): (typeof documents)["\nfragment TournamentFragment on Tournament {\n  id\n  state\n  name\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery EditTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    description\n    deadline\n    rules\n    label\n    image {\n      key\n    }\n    fields {\n      ...TournamentFieldsFragment\n    }\n    category\n    createdAt\n    state\n    awards {\n      medalId\n      userId\n    }\n    listPriority\n    medals {\n      ...MedalCardFragment\n    }\n    participants(limit: 100) {\n      items {\n        user {\n          id\n          username\n          fullName\n        }\n      }\n      nextToken\n    }\n    submissionsCount\n  }\n}\n"): (typeof documents)["\nquery EditTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    description\n    deadline\n    rules\n    label\n    image {\n      key\n    }\n    fields {\n      ...TournamentFieldsFragment\n    }\n    category\n    createdAt\n    state\n    awards {\n      medalId\n      userId\n    }\n    listPriority\n    medals {\n      ...MedalCardFragment\n    }\n    participants(limit: 100) {\n      items {\n        user {\n          id\n          username\n          fullName\n        }\n      }\n      nextToken\n    }\n    submissionsCount\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery MedalsQuery {\n  medals(limit: 100) {\n    items {\n      id\n      name\n      description\n      image {\n        key\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery MedalsQuery {\n  medals(limit: 100) {\n    items {\n      id\n      name\n      description\n      image {\n        key\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment MedalCardFragment on Medal {\n  id\n  name\n  description\n  upvotesReward\n  votePowerReward\n  durationDays\n  image {\n    key\n  }\n  money\n}\n"): (typeof documents)["\nfragment MedalCardFragment on Medal {\n  id\n  name\n  description\n  upvotesReward\n  votePowerReward\n  durationDays\n  image {\n    key\n  }\n  money\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddMedalToTournament($tournamentId: ID!, $medalId: ID!) {\n    addMedalToTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...AddMedalToTournamentFragment\n    }\n  }\n  "): (typeof documents)["\n  mutation AddMedalToTournament($tournamentId: ID!, $medalId: ID!) {\n    addMedalToTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...AddMedalToTournamentFragment\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AddMedalToTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  "): (typeof documents)["\n  fragment AddMedalToTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation AddMedal($input: MedalInput!) {\n  addMedal(input: $input) {\n    ...MedalCardFragment\n  }\n}\n"): (typeof documents)["\nmutation AddMedal($input: MedalInput!) {\n  addMedal(input: $input) {\n    ...MedalCardFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteMedalFromTournament($tournamentId: ID!, $medalId: ID!) {\n    deleteMedalFromTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...DeleteMedalFromTournamentFragment\n    }\n  }\n  "): (typeof documents)["\n  mutation DeleteMedalFromTournament($tournamentId: ID!, $medalId: ID!) {\n    deleteMedalFromTournament(tournamentId: $tournamentId, medalId: $medalId) {\n      ...DeleteMedalFromTournamentFragment\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment DeleteMedalFromTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  "): (typeof documents)["\n  fragment DeleteMedalFromTournamentFragment on Tournament {\n    id\n    medals {\n      ...MedalCardFragment\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateTournament($id: ID!, $input: TournamentInput!) {\n  updateTournament(id: $id, input: $input) {\n    ...UpdateTournamentFragment\n  }\n}\n"): (typeof documents)["\nmutation UpdateTournament($id: ID!, $input: TournamentInput!) {\n  updateTournament(id: $id, input: $input) {\n    ...UpdateTournamentFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment UpdateTournamentFragment on Tournament {\n  id\n  state\n  label\n  category\n  name\n  headline\n  description\n  rules\n  deadline\n  fields {\n    type\n    name\n  }\n  awards {\n    medalId\n    userId\n  }\n  image {\n    key\n  }\n  listPriority\n}\n"): (typeof documents)["\nfragment UpdateTournamentFragment on Tournament {\n  id\n  state\n  label\n  category\n  name\n  headline\n  description\n  rules\n  deadline\n  fields {\n    type\n    name\n  }\n  awards {\n    medalId\n    userId\n  }\n  image {\n    key\n  }\n  listPriority\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery UserInviteCodesQuery {\n  me {\n    protectedFields {\n      inviteCodes {\n        ...UserInviteCodeFragment\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery UserInviteCodesQuery {\n  me {\n    protectedFields {\n      inviteCodes {\n        ...UserInviteCodeFragment\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddUserInviteCode {\n    addInviteCode {\n      ...UserInviteCodeFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddUserInviteCode {\n    addInviteCode {\n      ...UserInviteCodeFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserInviteCodeFragment on InviteCodeV2 {\n    code\n    createdAt\n    id\n    invitedUser {\n      id\n      ...UserBadgeFragment\n    }\n  }\n"): (typeof documents)["\n  fragment UserInviteCodeFragment on InviteCodeV2 {\n    code\n    createdAt\n    id\n    invitedUser {\n      id\n      ...UserBadgeFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment LeaderCardFragment on LeaderbordItem {\n  user {\n    ...UserCardFragment\n  }\n  score\n}\n"): (typeof documents)["\nfragment LeaderCardFragment on LeaderbordItem {\n  user {\n    ...UserCardFragment\n  }\n  score\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery LeaderboardQuery($type: LeaderboardType!) {\n  leaderboard(type: $type) {\n    ...LeaderCardFragment\n  }\n}\n"): (typeof documents)["\nquery LeaderboardQuery($type: LeaderboardType!) {\n  leaderboard(type: $type) {\n    ...LeaderCardFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query NotificationsQuery($limit: Int!, $nextToken: String) {\n    notifications(limit: $limit, nextToken: $nextToken) {\n      items {\n        ...NotificationItemFragment\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query NotificationsQuery($limit: Int!, $nextToken: String) {\n    notifications(limit: $limit, nextToken: $nextToken) {\n      items {\n        ...NotificationItemFragment\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription OnNotificationsUpdated {\n    onNotification {\n      ...NotificationItemFragment\n    }\n  }\n"): (typeof documents)["\n  subscription OnNotificationsUpdated {\n    onNotification {\n      ...NotificationItemFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation ReadNotification($notificationId: ID!) {\n    readNotification(id: $notificationId) {\n      ...NotificationItemFragment\n    }\n  }\n"): (typeof documents)["\n  mutation ReadNotification($notificationId: ID!) {\n    readNotification(id: $notificationId) {\n      ...NotificationItemFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CommentFragment on CommentPost {\n    id\n    json\n    createdAt\n    voteType\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      id\n      fullName\n      username\n      avatar {\n        key\n      }\n    }\n    upvotes\n    downvotes\n    voted\n    commentLevel\n    commentCount\n    parentPostId\n    __typename\n  }\n"): (typeof documents)["\n  fragment CommentFragment on CommentPost {\n    id\n    json\n    createdAt\n    voteType\n    media {\n      ...PostCardMediaFragment\n    }\n    author {\n      id\n      fullName\n      username\n      avatar {\n        key\n      }\n    }\n    upvotes\n    downvotes\n    voted\n    commentLevel\n    commentCount\n    parentPostId\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CommentsQuery($postId: ID!, $limit: Int!, $nextToken: String, $voteType: VoteTypeFilter, $orderBy: CommentOrderBy, $order: Order) {\ncomments(parentPostId: $postId, limit: $limit, nextToken: $nextToken, voteType: $voteType, orderBy: $orderBy, order: $order) {\n  items {\n    ...CommentFragment\n  }\n  nextToken\n}\n}"): (typeof documents)["query CommentsQuery($postId: ID!, $limit: Int!, $nextToken: String, $voteType: VoteTypeFilter, $orderBy: CommentOrderBy, $order: Order) {\ncomments(parentPostId: $postId, limit: $limit, nextToken: $nextToken, voteType: $voteType, orderBy: $orderBy, order: $order) {\n  items {\n    ...CommentFragment\n  }\n  nextToken\n}\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddComment($input: CommentPostInput!) {\n    addComment(input: $input) {\n      ...CommentFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddComment($input: CommentPostInput!) {\n    addComment(input: $input) {\n      ...CommentFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment RegularPostCommentCountFragment on RegularPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n"): (typeof documents)["\nfragment RegularPostCommentCountFragment on RegularPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentPostCommentCountFragment on TournamentPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n"): (typeof documents)["\nfragment TournamentPostCommentCountFragment on TournamentPost {\n  id\n  commentCount\n  upvoteCommentCount\n  downvoteCommentCount\n  __typename\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CommentPostCommentCountFragment on CommentPost {\n    id\n    commentCount\n    __typename\n  }\n"): (typeof documents)["\n  fragment CommentPostCommentCountFragment on CommentPost {\n    id\n    commentCount\n    __typename\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UnauthenticatedPostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on RePost {\n        ...UnauthenticatedRepostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query UnauthenticatedPostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        ...UnauthenticatedRegularPostCardFragment\n      }\n      ... on RePost {\n        ...UnauthenticatedRepostCardFragment\n      }\n      ... on TournamentPost {\n        ...BaseTournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...BaseStreamPostCardFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...PostCardFragment\n      }\n      ... on RePost {\n        originalPost {\n          upvoteCommentCount\n          downvoteCommentCount\n        }\n\n        ...RepostCardFragment\n      }\n      ... on TournamentPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PostPageQuery($id: ID!) {\n    post(id: $id) {\n      ... on RegularPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...PostCardFragment\n      }\n      ... on RePost {\n        originalPost {\n          upvoteCommentCount\n          downvoteCommentCount\n        }\n\n        ...RepostCardFragment\n      }\n      ... on TournamentPost {\n        upvoteCommentCount\n        downvoteCommentCount\n        ...TournamentPostCardFragment\n      }\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query CommentByIdQuery($id: ID!) {\ncomment(id: $id) {\n  ...CommentFragment\n}\n}"): (typeof documents)["query CommentByIdQuery($id: ID!) {\ncomment(id: $id) {\n  ...CommentFragment\n}\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment SearchResultsFragment on SearchResultPage {\n  items {\n    __typename\n    id\n    ... on RegularPost {\n      ...PostCardFragment\n    }\n    ... on User {\n      ...FollowableUserCardFragment\n    }\n    ... on Tournament {\n      ...TournamentCardFragment\n    }\n    ... on Stream {\n      ...StreamCardFragment\n    }\n  }\n  offset\n}\n"): (typeof documents)["\nfragment SearchResultsFragment on SearchResultPage {\n  items {\n    __typename\n    id\n    ... on RegularPost {\n      ...PostCardFragment\n    }\n    ... on User {\n      ...FollowableUserCardFragment\n    }\n    ... on Tournament {\n      ...TournamentCardFragment\n    }\n    ... on Stream {\n      ...StreamCardFragment\n    }\n  }\n  offset\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SearchQuery($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n}\n"): (typeof documents)["\nquery SearchQuery($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SearchQueryWithItemsPreview($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n  searchPeoplePreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [User]}}) {\n    ...SearchResultsFragment\n  }\n  searchTournamentsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Tournament]}}) {\n    ...SearchResultsFragment\n  }\n  searchStreamsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Stream]}}) {\n    ...SearchResultsFragment\n  }\n}\n"): (typeof documents)["\nquery SearchQueryWithItemsPreview($text: String!, $limit: Int!, $offset: Int!, $type: SearchType!) {\n  search(input: {limit: $limit, offset: $offset, text: $text, filters: {type: [$type]}})  {\n    ...SearchResultsFragment\n  }\n  searchPeoplePreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [User]}}) {\n    ...SearchResultsFragment\n  }\n  searchTournamentsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Tournament]}}) {\n    ...SearchResultsFragment\n  }\n  searchStreamsPreview: search(input: {limit: 20, offset: 0, text: $text, filters: {type: [Stream]}}) {\n    ...SearchResultsFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StreamPublisherApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n    streamName\n    streamId\n  }\n"): (typeof documents)["\n  fragment StreamPublisherApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n    streamName\n    streamId\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment StreamReaderApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n  }\n"): (typeof documents)["\n  fragment StreamReaderApiKeyFragment on StreamApiKey {\n    id\n    secretHint\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ApiKeysQuery {\n    me {\n      id\n      protectedFields {\n        streamPublisherApiKeys {\n          ...StreamPublisherApiKeyFragment\n        }\n        streamReaderApiKeys {\n          ...StreamReaderApiKeyFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApiKeysQuery {\n    me {\n      id\n      protectedFields {\n        streamPublisherApiKeys {\n          ...StreamPublisherApiKeyFragment\n        }\n        streamReaderApiKeys {\n          ...StreamReaderApiKeyFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ConnectedAccountsQuery {\n    me {\n      id\n      xComHandle\n    }\n}\n"): (typeof documents)["\n  query ConnectedAccountsQuery {\n    me {\n      id\n      xComHandle\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery SettingsNotifications {\n  settings {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"): (typeof documents)["\nquery SettingsNotifications {\n  settings {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddPushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    addPushNotificationsSubscription(input: $input)\n  }\n"): (typeof documents)["\n  mutation AddPushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    addPushNotificationsSubscription(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemovePushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    removePushNotificationsSubscription(input: $input)\n  }\n"): (typeof documents)["\n  mutation RemovePushNotificationsSubscription($input: PushNotificationSubscriptionInput!) {\n    removePushNotificationsSubscription(input: $input)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateGeneralNotificationSettings($input: UpdateGeneralNotificationSettingsInput!) {\n  updateGeneralNotificationSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"): (typeof documents)["\nmutation UpdateGeneralNotificationSettings($input: UpdateGeneralNotificationSettingsInput!) {\n  updateGeneralNotificationSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation UpdateNotificationSettings($input: UpdateSettingsInput!) {\n  updateSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"): (typeof documents)["\nmutation UpdateNotificationSettings($input: UpdateSettingsInput!) {\n  updateSettings(input: $input) {\n    notifications {\n      IN_APP\n      EMAIL\n      mentions\n      commentToAuthoredPost\n      newFollowers\n      subscribedAuthorPost\n      system\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query PersonalDetailsQuery {\n    me {\n      id\n      username\n      protectedFields {\n        email\n      }\n      xComHandle\n    }\n}\n"): (typeof documents)["\n  query PersonalDetailsQuery {\n    me {\n      id\n      username\n      protectedFields {\n        email\n      }\n      xComHandle\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription OnCustomCollectionPostUpdate($collectionId: ID!) {\n    onCustomCollectionPostUpdate(collectionId: $collectionId) {\n      post {\n        ...CustomCollectionPostFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription OnCustomCollectionPostUpdate($collectionId: ID!) {\n    onCustomCollectionPostUpdate(collectionId: $collectionId) {\n      post {\n        ...CustomCollectionPostFragment\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomCollectionPostFragment on StreamPost {\n    ...StreamPostCardFragment\n  }\n"): (typeof documents)["\n  fragment CustomCollectionPostFragment on StreamPost {\n    ...StreamPostCardFragment\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomCollectionSourceFragment on Stream {\n    id\n    name\n    description\n  }\n"): (typeof documents)["\n  fragment CustomCollectionSourceFragment on Stream {\n    id\n    name\n    description\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment CustomCollectionFragment on CustomCollection {\n    id\n    name\n    sources {\n      ...CustomCollectionSourceFragment\n    }\n    filters {\n      excludeKeywords {\n        all\n        any\n        phrase\n      }\n      includeKeywords {\n        all\n        any\n        phrase\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CustomCollectionFragment on CustomCollection {\n    id\n    name\n    sources {\n      ...CustomCollectionSourceFragment\n    }\n    filters {\n      excludeKeywords {\n        all\n        any\n        phrase\n      }\n      includeKeywords {\n        all\n        any\n        phrase\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomCollections {\n    customCollections {\n      ...CustomCollectionFragment\n    }\n  }\n"): (typeof documents)["\n  query GetCustomCollections {\n    customCollections {\n      ...CustomCollectionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddCustomCollection($input: AddCustomCollectionInput!) {\n    addCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddCustomCollection($input: AddCustomCollectionInput!) {\n    addCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query GetCustomCollectionPosts($collectionId: ID!, $limit: Int, $nextToken: String) {\n    customCollectionPosts(collectionId: $collectionId, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...CustomCollectionPostFragment\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query GetCustomCollectionPosts($collectionId: ID!, $limit: Int, $nextToken: String) {\n    customCollectionPosts(collectionId: $collectionId, limit: $limit, nextToken: $nextToken) {\n      items {\n        ...CustomCollectionPostFragment\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCustomCollection($input: UpdateCustomCollectionInput!) {\n    updateCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCustomCollection($input: UpdateCustomCollectionInput!) {\n    updateCustomCollection(input: $input) {\n      ...CustomCollectionFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveCustomCollection($id: ID!) {\n    removeCustomCollection(id: $id)\n  }\n"): (typeof documents)["\n  mutation RemoveCustomCollection($id: ID!) {\n    removeCustomCollection(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateCustomCollectionSortOrder($sortOrder: [String!]!) {\n    updateCustomCollectionSortOrder(sortOrder: $sortOrder)\n  }\n"): (typeof documents)["\n  mutation UpdateCustomCollectionSortOrder($sortOrder: [String!]!) {\n    updateCustomCollectionSortOrder(sortOrder: $sortOrder)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamsQuery($queryType: StreamQueryType!, $limit: Int!, $nextToken: String, $streamCategory: StreamCategory) {\n  streams(queryType: $queryType, limit: $limit, nextToken: $nextToken, streamCategory: $streamCategory) {\n    items {\n      ...StreamCardFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery StreamsQuery($queryType: StreamQueryType!, $limit: Int!, $nextToken: String, $streamCategory: StreamCategory) {\n  streams(queryType: $queryType, limit: $limit, nextToken: $nextToken, streamCategory: $streamCategory) {\n    items {\n      ...StreamCardFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamSearchQuery($query: String!, $offset: Int!, $streamCategory: StreamCategory) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Stream], stream: {streamCategory: $streamCategory}}}) {\n    items {\n      ...StreamCardFragment\n    }\n    offset\n  }\n}\n"): (typeof documents)["\nquery StreamSearchQuery($query: String!, $offset: Int!, $streamCategory: StreamCategory) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Stream], stream: {streamCategory: $streamCategory}}}) {\n    items {\n      ...StreamCardFragment\n    }\n    offset\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnStreamPostsUpdated($streamId: ID!) {\n  streamPostsUpdates(streamId: $streamId) {\n    ...StreamPostCardFragment\n  }\n}\n"): (typeof documents)["\nsubscription OnStreamPostsUpdated($streamId: ID!) {\n  streamPostsUpdates(streamId: $streamId) {\n    ...StreamPostCardFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamQuery($id: ID!) {\n  stream(id: $id) {\n    id\n    accessAllowed\n    author {\n      id\n      username\n    }\n    name\n    description\n    following\n    label\n    upvotes\n    downvotes\n    streamCategory\n    monthlyPostFrequency\n    streamState\n    isEmpty\n    protectedFields {\n      streamApiKey {\n        id\n        secretHint\n      }\n    }\n    paidStreamData {\n      price\n      sourceOfData\n    }\n    subscription {\n      cancelAt\n    }\n  }\n}\n"): (typeof documents)["\nquery StreamQuery($id: ID!) {\n  stream(id: $id) {\n    id\n    accessAllowed\n    author {\n      id\n      username\n    }\n    name\n    description\n    following\n    label\n    upvotes\n    downvotes\n    streamCategory\n    monthlyPostFrequency\n    streamState\n    isEmpty\n    protectedFields {\n      streamApiKey {\n        id\n        secretHint\n      }\n    }\n    paidStreamData {\n      price\n      sourceOfData\n    }\n    subscription {\n      cancelAt\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery StreamPostsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  streamPosts(id: $id, limit: $limit, nextToken: $nextToken) {\n    items {\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery StreamPostsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  streamPosts(id: $id, limit: $limit, nextToken: $nextToken) {\n    items {\n      ... on StreamPost {\n        ...StreamPostCardFragment\n      }\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UnauthenticatedStreamQuery($id: ID!) {\n    stream(id: $id) {\n      id\n      author {\n        id\n        username\n        xComHandle\n      }\n      name\n      description\n      label\n      upvotes\n      downvotes\n    }\n  }\n"): (typeof documents)["\n  query UnauthenticatedStreamQuery($id: ID!) {\n    stream(id: $id) {\n      id\n      author {\n        id\n        username\n        xComHandle\n      }\n      name\n      description\n      label\n      upvotes\n      downvotes\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation FollowStream($streamId: ID!) {\n    followStream(streamId: $streamId)\n  }\n"): (typeof documents)["\n  mutation FollowStream($streamId: ID!) {\n    followStream(streamId: $streamId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UnfollowStream($streamId: ID!) {\n    unfollowStream(streamId: $streamId)\n  }\n"): (typeof documents)["\n  mutation UnfollowStream($streamId: ID!) {\n    unfollowStream(streamId: $streamId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment StreamFollowingFragment on Stream {\n  following\n}"): (typeof documents)["\nfragment StreamFollowingFragment on Stream {\n  following\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TournamentsSearchQuery($query: String!, $offset: Int!, $category: String, $closed: Boolean = null) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Tournament], tournament: {category: $category, closed: $closed}}}) {\n    items {\n      ... on Tournament {\n        ...TournamentCardFragment\n      }\n    }\n    offset\n  }\n}\n"): (typeof documents)["\nquery TournamentsSearchQuery($query: String!, $offset: Int!, $category: String, $closed: Boolean = null) {\n  search(input: {limit: 20, offset: $offset, text: $query, filters: {type: [Tournament], tournament: {category: $category, closed: $closed}}}) {\n    items {\n      ... on Tournament {\n        ...TournamentCardFragment\n      }\n    }\n    offset\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentCardFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  headline\n  category\n  medals {\n    money\n  }\n  ...TournamentStatusFragment\n}\n"): (typeof documents)["\nfragment TournamentCardFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  headline\n  category\n  medals {\n    money\n  }\n  ...TournamentStatusFragment\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentOverviewFragment on Tournament {\n  id\n  description\n  rules\n  state\n  label\n  participants(limit: 5, nextToken: null) {\n    items {\n      id\n      submissionsCount\n      user {\n        id\n        fullName\n        avatar {\n          key\n        }\n      }\n    }\n    nextToken\n  }\n  participantsCount\n  medals {\n   ...MedalCardFragment\n  }\n  deadline\n  mySubmissions {\n    id\n  }\n}\n"): (typeof documents)["\nfragment TournamentOverviewFragment on Tournament {\n  id\n  description\n  rules\n  state\n  label\n  participants(limit: 5, nextToken: null) {\n    items {\n      id\n      submissionsCount\n      user {\n        id\n        fullName\n        avatar {\n          key\n        }\n      }\n    }\n    nextToken\n  }\n  participantsCount\n  medals {\n   ...MedalCardFragment\n  }\n  deadline\n  mySubmissions {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TournamentParticipantsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    participants(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        user{\n          ...UserCardFragment\n        }\n        submissionsCount\n      }\n      nextToken\n    }\n  }\n}\n"): (typeof documents)["\nquery TournamentParticipantsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    participants(limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        user{\n          ...UserCardFragment\n        }\n        submissionsCount\n      }\n      nextToken\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentAwardsFragment on Tournament {\n  state\n  awards {\n    medalId\n    user {\n      ...UserCardFragment\n    }\n  }\n}\n"): (typeof documents)["\nfragment TournamentAwardsFragment on Tournament {\n  state\n  awards {\n    medalId\n    user {\n      ...UserCardFragment\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentMySubmissionsFragment on Tournament {\nstate\nmySubmissions {\n  ...TournamentPostCardFragment\n}\n}\n"): (typeof documents)["\nfragment TournamentMySubmissionsFragment on Tournament {\nstate\nmySubmissions {\n  ...TournamentPostCardFragment\n}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BaseTournamentPostFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n  }\n"): (typeof documents)["\n  fragment BaseTournamentPostFragment on TournamentPost {\n    id\n    upvotes\n    downvotes\n    createdAt\n    releaseAt\n    released\n    deletedAt\n    commentCount\n    author {\n      ...UnauthenticatedPostCardAuthorFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    ...TournamentBannerFragment\n    ...TournamentMySubmissionsFragment\n    ...TournamentOverviewFragment\n    ...TournamentAwardsFragment\n    ...TournamentPostFormFragment\n  }\n}\n"): (typeof documents)["\nquery TournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    ...TournamentBannerFragment\n    ...TournamentMySubmissionsFragment\n    ...TournamentOverviewFragment\n    ...TournamentAwardsFragment\n    ...TournamentPostFormFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery TournamentSubmissionsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    submissions(limit: $limit, nextToken: $nextToken) {\n      items {\n          ...TournamentPostCardFragment\n      }\n      nextToken\n    }\n  }\n}\n"): (typeof documents)["\nquery TournamentSubmissionsQuery($id: ID!, $limit: Int!, $nextToken: String) {\n  tournament(id: $id) {\n    id\n    submissions(limit: $limit, nextToken: $nextToken) {\n      items {\n          ...TournamentPostCardFragment\n      }\n      nextToken\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentPostFormFragment on Tournament {\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}\n"): (typeof documents)["\nfragment TournamentPostFormFragment on Tournament {\n  label\n  fields {\n    ...TournamentFieldsFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentBannerFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  medals {\n    ...MedalCardFragment\n  }\n  headline\n  category\n  ...TournamentStatusFragment\n}\n"): (typeof documents)["\nfragment TournamentBannerFragment on Tournament {\n  id\n  name\n  image {\n    key\n  }\n  medals {\n    ...MedalCardFragment\n  }\n  headline\n  category\n  ...TournamentStatusFragment\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment TournamentStatusFragment on Tournament {\n  state\n  participantsCount\n  deadline\n}\n"): (typeof documents)["\nfragment TournamentStatusFragment on Tournament {\n  state\n  participantsCount\n  deadline\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nsubscription OnTournamentSubmissions($tournamentId: ID!) {\n  tournamentPostsUpdates(tournamentId: $tournamentId) {\n    ...TournamentPostCardFragment\n  }\n}\n"): (typeof documents)["\nsubscription OnTournamentSubmissions($tournamentId: ID!) {\n  tournamentPostsUpdates(tournamentId: $tournamentId) {\n    ...TournamentPostCardFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery UnauthenticatedTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    image {\n     key\n    }\n    medals {\n      ...MedalCardFragment\n    }\n    category\n    ...TournamentStatusFragment\n  }\n}\n"): (typeof documents)["\nquery UnauthenticatedTournamentQuery($id: ID!) {\n  tournament(id: $id) {\n    id\n    name\n    headline\n    image {\n     key\n    }\n    medals {\n      ...MedalCardFragment\n    }\n    category\n    ...TournamentStatusFragment\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AddTournamentPost($input: TournamentPostInput!) {\n    addTournamentPost(input: $input) {\n      ...TournamentPostCardFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddTournamentPost($input: TournamentPostInput!) {\n    addTournamentPost(input: $input) {\n      ...TournamentPostCardFragment\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ActiveTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments(limit: $limit, nextToken: $nextToken, category: $category, state: ACTIVE) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery ActiveTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments(limit: $limit, nextToken: $nextToken, category: $category, state: ACTIVE) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery ClosedTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments: closedTournaments(limit: $limit, nextToken: $nextToken, category: $category) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n"): (typeof documents)["\nquery ClosedTournamentsQuery($limit: Int!, $nextToken: String, $category: String) {\n  tournaments: closedTournaments(limit: $limit, nextToken: $nextToken, category: $category) {\n    items {\n      ...TournamentCardFragment\n    }\n    nextToken\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query UnreadNotificationsQuery($limit: Int!, $read: Boolean!) {\n    notifications(read: $read, limit: $limit, nextToken: null) {\n      items {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query UnreadNotificationsQuery($limit: Int!, $read: Boolean!) {\n    notifications(read: $read, limit: $limit, nextToken: null) {\n      items {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query meQuery {\n    me {\n      id\n      username\n      fullName\n      createdAt\n      bio\n      protectedFields {\n        email\n        isOnboardingFinished\n        onboardingProgress\n        interests\n        possibleUsernames\n        intercomHmac\n        stripeSellerAccount {\n          ...StripeSellerProfileFragment\n        }\n      }\n      avatar {\n        key\n      }\n    }\n  }\n"): (typeof documents)["\n  query meQuery {\n    me {\n      id\n      username\n      fullName\n      createdAt\n      bio\n      protectedFields {\n        email\n        isOnboardingFinished\n        onboardingProgress\n        interests\n        possibleUsernames\n        intercomHmac\n        stripeSellerAccount {\n          ...StripeSellerProfileFragment\n        }\n      }\n      avatar {\n        key\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query flagsQuery {\n    flags\n  }\n"): (typeof documents)["\n  query flagsQuery {\n    flags\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nfragment MeMinimalFragment on User {\n  id\n  fullName\n  username\n}\n"): (typeof documents)["\nfragment MeMinimalFragment on User {\n  id\n  fullName\n  username\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ChooseUsernameDataQuery {\n    me {\n      id\n      protectedFields {\n        possibleUsernames\n      }\n      xComHandle\n    }\n}\n"): (typeof documents)["\n  query ChooseUsernameDataQuery {\n    me {\n      id\n      protectedFields {\n        possibleUsernames\n      }\n      xComHandle\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateUsernames {\n    generatePossibleUsernames\n  }\n"): (typeof documents)["\n  mutation GenerateUsernames {\n    generatePossibleUsernames\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery PostPreviewQuery($id: ID!) {\n  post(id: $id) {\n    ... on RegularPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      reputationBet\n      __typename\n    }\n    ... on RePost {\n      id\n      originalPost {\n        id\n        json\n        author {\n          ...UserBadgeFragment\n        }\n        reputationBet\n        __typename\n      }\n      __typename\n    }\n    ... on TournamentPost {\n      id\n      fields {\n        name\n        value\n      }\n      author {\n        ...UserBadgeFragment\n      }\n      tournament {\n        ...TournamentPostTournamentFragment\n      }\n      __typename\n    }\n    ... on StreamPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      stream {\n        id\n        label\n      }\n      __typename\n    }\n  }\n}\n"): (typeof documents)["\nquery PostPreviewQuery($id: ID!) {\n  post(id: $id) {\n    ... on RegularPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      reputationBet\n      __typename\n    }\n    ... on RePost {\n      id\n      originalPost {\n        id\n        json\n        author {\n          ...UserBadgeFragment\n        }\n        reputationBet\n        __typename\n      }\n      __typename\n    }\n    ... on TournamentPost {\n      id\n      fields {\n        name\n        value\n      }\n      author {\n        ...UserBadgeFragment\n      }\n      tournament {\n        ...TournamentPostTournamentFragment\n      }\n      __typename\n    }\n    ... on StreamPost {\n      id\n      json\n      author {\n        ...UserBadgeFragment\n      }\n      stream {\n        id\n        label\n      }\n      __typename\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation RemoveXComHandle {\n    removeXComHandle {\n      xComHandle\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveXComHandle {\n    removeXComHandle {\n      xComHandle\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation SetXComHandle($code: String!) {\n    setXComHandle(code: $code) {\n      xComHandle\n      username\n    }\n  }\n"): (typeof documents)["\n  mutation SetXComHandle($code: String!) {\n    setXComHandle(code: $code) {\n      xComHandle\n      username\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;